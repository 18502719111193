import Link from 'next/link';
import Section from '../Section';
import React from 'react';
import Card from '../Card';
import classNames from 'classnames';

import styles from './ProjectSectionHome.module.css';

const ProjectSectionHome = ({ projects, businessVenture }) => {
  const proyects = [projects, businessVenture];
  return (
    <Link scroll={false} href='/projects' legacyBehavior>
      <Section
        className='container grid h-auto grid-cols-1 gap-2 px-8 py-0 overflow-visible md:grid-cols-2 md:gap-6 isolate'
        component='section'
      >
        {proyects.map((item, index) => (
          <HomeCard
            key={index}
            {...item}
            isLast={index === proyects.length - 1}
          />
        ))}
      </Section>
    </Link>
  );
};

export default ProjectSectionHome;

const HomeCard = ({ title, link, image, isLast, __typename }) => (
  <Card
    className={classNames(
      `bg-white flex relative w-full h-96 md:px-12 py-16 md:p-12 xl:p-6 md:mb-0 transition duration-500 hover:scale-105 hover:z-30 z-0 cursor-pointer select-none ${
        isLast ? 'mb-0' : 'mb-8'
      }  `,
      __typename === 'Page_Cfhomecards_Projects'
        ? 'bg-cover cursor-pointer bg-center'
        : 'bg-cover cursor-pointer bg-center'
    )}
    style={{
      backgroundImage: `url(${image?.sourceUrl})`,
    }}
    index={2}
    shadowHover
    isAnimated
  >
    <h2 className='mr-auto ml-auto md:max-w-sm md:text-7xl text-5xl font-now font-bold uppercase md:mb-3 mb-5 text-center leading-[2.5rem]'>
      {/* <Link  scroll={false}   href={link?.url} scroll={false}> */}
      {title}
      {/* </Link> */}
    </h2>
  </Card>
);
